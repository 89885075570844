















import { NavigationHelper } from '@projetlucie/lc-front-sdk-old';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {
  private goBack() {
    NavigationHelper.goBack(this.$router);
  }
}
